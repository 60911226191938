import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Box } from '@material-ui/core'
import theme from '../../theme'
import IndexBgImage from '../components/index/IndexBgImage'
import ConfirmContent from "../components/index/ConfirmContent";


const Confirm = props => {
  return (
    <Layout>
      <Seo title="Incubit" />

      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        bgcolor={theme.palette.secondary.dark}
        
      >
        <IndexBgImage bgcolor="secondary.dark" />

        <ConfirmContent />        
      </Box>
    </Layout>
  )
}

export default Confirm
